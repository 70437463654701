<template>
  <b-row>
    <b-col cols="12">
      <!-- Default Colors -->
      <b-card
        title="Liste des BL"
        class="background-color"
      >
        <b-row>
          <b-col md="4">
            <a href="https://docs.google.com/spreadsheets/d/14CC9ISOG892L8jTJK5lI2_zcT-Wml_pJZENTFCT36hk/" target="_blank"><u>Accéder au template</u></a>
            <h5 class="mt-1">Fichier :</h5>
            <b-form-file
              v-model="file"
              placeholder="Choisir un fichier ou glisser..."
              drop-placeholder="Drop fichier ici..."
              accept=".csv"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="text-center mt-2"
            md="2"
          >
            <b-form-group>
              <b-button
                variant="primary"
                :disabled="loading"
                @click="upload"
              >
                Lancer l'import
              </b-button>
            </b-form-group>
          </b-col>
          <b-col
            v-if="errors && errors.length"
            class="text-center mt-2"
            md="4"
          >
            <download-csv
              :data="errors"
              name="errors.csv"
            >
              <b-button
                class="mb-2"
                variant="warning"
              >
                Télécharger les {{ errors.length }} erreurs
              </b-button>
            </download-csv>
          </b-col>
        </b-row>
      </b-card>
      <!--/ Gradient color start -->
    </b-col>
    <b-col cols="12">
      <b-card>

        <b-table
          ref="selectableTable"
          selectable
          :items="bls"
          :fields="fields"
        >

          <template #cell(date)="data">
            <span>{{ data.item.date[0] }}</span>
          </template>
          <template #cell(logistic)="data">
            <span>{{ data.item.logistic[0] }}</span>
          </template>
          <template #cell(show_details)="data">
            <b-form-checkbox
              v-model="data.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="data.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ data.detailsShowing ? 'Cacher' : 'Voir' }}</span>
            </b-form-checkbox>
          </template>
          <template #cell(linkOrder)="data">
            <a v-if="data.item.links && data.item.links.length && data.item.links[0]" :href="data.item.links[0]" target="_blank">Order Link</a>
          </template>
          <template #row-details="data">
            <b-table-lite
              responsive
              :items="data.item.value"
              :fields="['sku', 'qtyReceived', 'dateReceived', 'link']"
            >
              <template #cell(dateReceived)="data">
                {{ data.item.dateReceived }}
              </template>
            </b-table-lite>
          </template>
          <template #cell(link)="data">
            <a :href="data.item.link" target="_blank">Link</a>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BFormFile, BTable, BFormCheckbox, BTableLite,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import JsonCSV from 'vue-json-csv'

export default {
  components: {
    BCard,
    BFormFile,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BTableLite,
    'download-csv': JsonCSV,
  },
  data() {
    return {
      file: null,
      rangeDate: null,
      selected: '',
      option: ['desktop', 'mobile'],
      fields: [{ key: 'show_details', label: 'Détails' }, '_id', 'date', 'logistic', { key: 'linkOrder', label: 'Link' }],
      products: [],
      selectedProduct: '',
      bls: [],
      errors: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getBl()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getBl() {
      try {
        this.loading = true
        const {
          bls,
        } = await this.$http.get('/admin/logistic/bl-list')
        this.bls = bls
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async upload(f) {
      this.errors = []
      console.log(this.selectedProduct)
      if (!this.file) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous devez mettre un fichier.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        console.log(f)
        setTimeout(() => {}, 2000)
        const form = new FormData()
        form.append('file', this.file, this.file.name)
        try {
          this.loading = true
          const { errors } = await this.$http.post('/admin/logistic/bl-upload', form)
          this.file = null
          this.errors = errors
          console.log(errors)
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: `L'import a bien reussi. ${errors.length ? `Mais il y a eu ${errors.length} erreurs.` : ''}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          await this.getBl()
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
        }
      }
    },
    async removeItem(item) {
      try {
        console.log(item)
        this.loading = true
        await this.$http.put('/admin/logistic/bl', { sku: item.sku, id: item.id })
        this.loading = false
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Suppression réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.getBl()
      } catch (err) {
        console.log(err)
      }
      console.log(item)
    },
  },
}
</script>

  <style lang="scss">
  .colors-list {
    li {
      padding: 0.4rem;
      svg {
        margin-right: 0.25rem;
      }
    }
  }
  </style>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
